import React from "react";
import CardImage1 from "../../assets/Images/Homepage/FullStackExpertise/Mobile-application-development.png";
import CardImage2 from "../../assets/Images/Homepage/FullStackExpertise/Web-application-development.png";
import CardImage3 from "../../assets/Images/Homepage/FullStackExpertise/MVP-Development.png";
import "./style.scss";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Link } from "@reach/router";
import BlurCardList from "../../templates/BlurCardList";
import MiniCardBgImage1 from "../../assets/Images/Homepage/mobile_application_development.png";
import MiniCardBgImage2 from "../../assets/Images/Homepage/web_application_development.png";
import MiniCardBgImage3 from "../../assets/Images/Homepage/full_product_development.png";
import MiniCardBgImage4 from "../../assets/Images/Homepage/MVP.png";
import MiniCardBgImage5 from "../../assets/Images/Homepage/Product_designing_and_Prototyping.png";
import MiniCardBgImage6 from "../../assets/Images/Homepage/product_scaling.png";
import MiniCardBgImage7 from "../../assets/Images/Homepage/code_ux_audit.png";
import MiniCardBgImage8 from "../../assets/Images/Homepage/digital_transformation.png";
import MiniCardBgImage9 from "../../assets/Images/Homepage/machine_learning.png";
import RevealAnimation from "../RevealAnimation";

function FullStackExpertise() {
  const BlurCardListData = {
    cardsData: [
      {
        image: MiniCardBgImage4,
        title: "MVP Development",
        subTitle:
          "Build a basic model to demonstrate the core functionality of your product, test it with early adopters and fulfil the primary goal of your business.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/mvp-development",
      },
      {
        image: MiniCardBgImage6,
        title: "Data Engineering",
        subTitle:
          "Create robust data pipelines and advanced analytics solutions, optimising processes, generating insights, and fostering innovation across your organisation",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/data-engineering",
      },
      {
        image: MiniCardBgImage9,
        title: "AI / ML",
        subTitle:
          "Through AI / ML, we can help you make informed decisions to improve business scalability and operational efficiency.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/machine-learning",
      },
      {
        image: MiniCardBgImage6,
        title: "Product Scaling",
        subTitle:
          "Found your product-market fit? Great! Let us help you with a profitable scaling process that provides both sustainability and stability for your product.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/product-scaling",
      },
      {
        image: MiniCardBgImage1,
        title: "Mobile Application Development",
        subTitle:
          "Take business to your customers by building advanced native and hybrid mobile apps for both Android and iOS Platforms.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/mobile-application-development",
      },
      {
        image: MiniCardBgImage2,
        title: "Web Application Development",
        subTitle:
          "Accelerate different functions of your business by creating custom web applications using the latest in digital technologies.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/web-application-development",
      },
      {
        image: MiniCardBgImage3,
        title: "Full Product Development",
        subTitle:
          "The process of full product development encompasses all steps needed to take your product from concept to market place.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/full-product-development",
      },
      {
        image: MiniCardBgImage5,
        title: "Product Designing and Prototyping",
        subTitle:
          "Create a prototype of your product to visualise your idea and generate feedback without incurring the building costs of a fully functioning system.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/product-designing-and-prototyping",
      },

      {
        image: MiniCardBgImage7,
        title: "Code & UX Audit",
        subTitle:
          "With a regular audit, we can help you fix your product, improve its performance, gain new users, and boost your brand reputation in the marketplace.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/code-ux-audit",
      },
      {
        image: MiniCardBgImage8,
        title: "Digital Transformation",
        subTitle:
          "Update your traditional business processes and customer experience to meet the ever-changing market requirements with the help of next-generation digital technologies.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/digital-transformation",
      },
    ],
  };
  return (
    <div className="our-modal-wrap ">
      <div className="body-container">
        <RevealAnimation
          component={"h2"}
          animation={"slide-left"}
          className="title-header mt-15 title-underline"
        >
          From Concept to Completion: <br /> Our Full-Stack Expertise
        </RevealAnimation>
      </div>
      <div className="mt-70">
        <BlurCardList
          componentId="1"
          loadMore
          BlurCardListData={BlurCardListData}
          loadMoreLabel="Show All"
        />
      </div>
    </div>
  );
}

export default FullStackExpertise;
