import React from "react";
import "./FlipCards.scss";
import OneImg from "../../assets/Images/Homepage/WhyBusiness/1.svg";
import TwoImg from "../../assets/Images/Homepage/WhyBusiness/2.svg";
import ThreeImg from "../../assets/Images/Homepage/WhyBusiness/3.svg";
import FourImg from "../../assets/Images/Homepage/WhyBusiness/4.svg";
import FiveImg from "../../assets/Images/Homepage/WhyBusiness/5.svg";
import SixImg from "../../assets/Images/Homepage/WhyBusiness/6.svg";
import RevealAnimation from "../RevealAnimation";

const FlipCards = (props) => {
  const dataList = [
    {
      srOnlyTitle: "Improve Speed and Agility",
      title: ["Improve Speed", "and Agility"],

      icon: OneImg,
      description:
        "At Digiryte, we have the ability to source IT resources with Fortune 500 experience within 10 days. Speed to market is paramount. By partnering with us to build and manage your offshore digital team, you accelerate your project timelines through our agile and streamlined process. We ensure rapid delivery and a competitive edge with our proven approach.",
    },
    {
      srOnlyTitle: "Assured Knowledge Retention",
      title: ["Assured Knowledge", "Retention"],

      icon: TwoImg,
      description:
        "Retaining top talent is key to digital success. Digiryte ensures easy retention of skilled professionals, fostering a culture of growth and satisfaction that drives high retention rates. Our model includes shadow developers for knowledge retention, giving you peace of mind by ensuring consistent high-performing offshore teams.",
    },
    {
      srOnlyTitle: "Unlock Specialised Talent",
      title: ["Unlock Specialised", "Talent"],

      icon: ThreeImg,
      description:
        "Digiryte empowers you to build an offshore team enriched with domain-specific expertise across diverse sectors. Our commitment to precision and efficiency in sourcing resources underscores the importance for growing businesses. We prioritise matching professionals with relevant industry experience to ensure progression without setbacks.",
    },
    {
      srOnlyTitle: "Reduce Operating Expenses",
      title: ["Reduce", "Operating", "Expenses"],

      icon: FourImg,
      description:
        "Operating costs can strain your finances, but with Digiryte, you can cut them significantly by establishing an offshore digital team. Our competitive pricing and cost-effective resources ensure quality without overspending. Maximise ROI and business growth with access to our CTO and UK based agile coaches, included in our packages.",
    },
    {
      srOnlyTitle: "Tech Team is ISO Compliant",
      title: ["Tech Team is", "ISO Compliant"],

      icon: FiveImg,
      description:
        "By partnering with Digiryte your tech team will automatically become ISO 9001 and 27001 compliant. We hold data production registration certification issued by the Information Commissioner's Office (ICO) and strictly follow the UK's Data Protection Act (DPA).",
    },
    {
      srOnlyTitle: "Protected by English Law and Insurance",
      title: ["Protected by", "English Law", "and Insurance"],

      icon: SixImg,
      description:
        "With our comprehensive insurance coverage, we ensure complete peace of mind through our UK-based operations.",
    },
  ];
  return (
    <div className="grid-cards-wrap">
      <div className="body-container">
        <RevealAnimation
          component={"h2"}
          className="title-header mt-15 title-underline"
        >
          Why Business & IT Leaders Partner <br /> with Digiryte to go Offshore
        </RevealAnimation>
        <div className="grid-cards-container">
          {dataList.map((data, index) => (
            <div key={index} className="grid-cards-item">
              <div className="card-inner">
                <div className="card-front">
                  <div>
                    <p className="sr-only">{data.srOnlyTitle}</p>
                    {data.title.map((title, index) => (
                      <p className="title">{title}</p>
                    ))}
                  </div>
                  <img src={data.icon} alt="" className="img-icon" />
                </div>
                <div className="card-back">
                  <p className="title">{data.srOnlyTitle}</p>
                  <p className="description">{data.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FlipCards;
